<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t("singleSupplierRegister") }}
				</AppText>
			</div>
		</div>

		<div class="section__body" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<div class="table-block" :class="isMobileMedium ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th class="text-bold">{{ $t("tableSupllierName") }}</th>
							<th class="text-bold">{{ $t("statusName") }}</th>
							<th class="text-bold">{{ $t("inn") }}</th>
							<th class="text-bold">{{ $t("tableSupllierType") }}</th>
							<th class="text-bold">{{ $t("codeOfKlassifikator") }}</th>
							<th class="text-bold">{{ $t("dateOfSupplierIn") }}</th>
							<th class="text-bold">{{ $t("purpose") }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tableContent" class="text-center">
							<td>
								{{ item.provider_name }}
							</td>
							<td>
								{{ item.holati }}
							</td>
							<td>
								{{ item.inn }}
							</td>
							<td>
								{{ item.tovar_ish_his_nommi }}
							</td>
							<td>
								{{ item.enkt_code }}
							</td>
							<td>
								{{ item.date }}
							</td>
							<td>
								{{ item.asos }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<pagination
				:total="this.pageConfig.elementsCount"
				:perPage="this.pageConfig.selectedCount"
				@paginate="toPage($event)"
			></pagination>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import AppInput from "../../components/shared-components/AppInput";
import AppButton from "../../components/shared-components/AppButton";
import AppPagination from "../../components/shared-components/AppPagination";
import Pagination from "../../components/shared-components/Pagination";
import { companyTypesNames } from "@/static/statusIds";
import * as reyestr from "@/assets/files/reyestr_executors-register.json";

export default {
	name: "index",
	components: { AppPagination, AppButton, AppInput, AppText, Pagination },
	data() {
		return {
			pageConfig: {
				filters: {
					tin: null,
				},
				activePage: 1,
				elementsCount: 0,
				selectedCount: 10,
			},
			tableContent: [],
			companyTypesNames,
		};
	},
	created() {
		this.tableContent = reyestr.default;
		// this.toPage();
	},
	methods: {
		downloadExcel() {
			document.querySelector("a.excel").click();
		},
		// toPage(
		// 	page = this.pageConfig.activePage,
		// 	pageCount = this.pageConfig.selectedCount
		// ) {
		// 	this.pageConfig.activePage = page;
		// 	this.pageConfig.selectedCount = pageCount;
		// 	page--;
		// 	let params = {
		// 		...this.pageConfig.filters,
		// 		skip: page,
		// 		take: this.pageConfig.selectedCount,
		// 	};
		// 	this.$store.commit("setLoadingStatus", true);
		// 	this.$api
		// 		.get(`/api-client/Register/GetManufacturersOrDealers/GetManufacturersOrDealers`, {params})
		// 		.then(
		// 			(response) => {
		// 				console.log(response.data.result.data);
		// 				this.tableContent = response.data.result.data;
		// 				this.pageConfig.elementsCount = response.data.result.total;
		// 			}
		// 		)
		// 		.finally(() => {
		// 			this.$store.commit("setLoadingStatus", false);
		// 		});
		// },
	},
};
</script>

<style scoped></style>

